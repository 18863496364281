import fetch from "isomorphic-fetch"
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client"

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.GRAPHQL_URI,
    fetch,
    fetchOptions: {
      "Access-Control-Allow-Origin": "*",
    },
  }),
  cache: new InMemoryCache(),
})
